import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CommonService } from './common.service';
import { serviceAPI } from '../constant/service-api';
@Injectable({ providedIn: 'root' })
export class ProblemReportService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  /**
   * get list problem report
   * @param param link url
   */
  getListProblemReport(param): Observable<any> {
    const api = this.commonService.buildAPIUrl(serviceAPI.problems.getListProblems, param);
    return this.http.get(api);
  }
  /**
   * confirmed Problem Report
   * @param param link url
   * @param body send server
   */
  confirmedProblemReport(param, body): Observable<any> {
    const api = this.commonService.buildAPIUrl(serviceAPI.problems.confirmedProblems, param);
    return this.http.put(api, body);
  }

  /**
   * get Notifications Count
   *
   */
  getNotificationsCount(): Observable<any> {
    const api = this.commonService.buildAPIUrl(serviceAPI.problems.getNotificationsCount);
    return this.http.get(api);
  }

  /**
   * Export Problem report as CSV file
   */
  exportCSV() {
    const api = this.commonService.buildAPIUrl(serviceAPI.problems.export);
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.get<Blob>(api, httpOptions);
  }
}
