import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class ProjectStoreService {

  public stRecent: EventEmitter<any> = new EventEmitter<any>();
  public changeProject: EventEmitter<any> = new EventEmitter<any>();
  public storage: any;
  /*
  * Method listening when user view project
  * @param data of project
  *
  */
  listeningRecentProject(data) {
    this.stRecent.emit(data);
  }

  listeningChangeProject(data) {
    this.changeProject.emit({...data});
  }
  /**
   * Invoke change status event
   * @param status of version
   */
  // listenChangeVersionStatus(status){
  //   this.stChangeVersionStatus.emit(status);
  // }
}
