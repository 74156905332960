export const UserRoles = {
  systemAdmin: 'systemAdmin',
  supervisor: 'supervisor',
  normalUser: 'user', // other roles
  othersUser: 'others', // same role with user roles
};
export const UserPrivilegeDescription = {
  storeManagement: '店舗管理（店名などの店舗設定、営業時間の設定が可能）',
  userManagement: 'ユーザ管理（閲覧ユーザの登録・更新・削除が可能）',
  shelfManagement: '棚管理（棚の設定および通知設定の登録・更新・削除が可能）',
  checkLatestCapture: 'カメラ画像確認（モバイルアプリでカメラ画像を確認可能）',
  priceTagDetection: '値札チェック'
};
