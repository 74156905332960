import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingIndicatorService {
  public emitter: EventEmitter<{ isLoading: boolean }> = new EventEmitter<any>();

  constructor() {}

  show() {
    this.emitter.emit({ isLoading: true });
  }

  hide() {
    this.emitter.emit({ isLoading: false });
  }
}
