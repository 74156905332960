import {EventEmitter, Injectable} from '@angular/core';
import {storageManager} from '../constant/storage-manager';

@Injectable()
export class AuthService {
  public stLogin: EventEmitter<any> = new EventEmitter<any>();

  /*
  * Method get authen token local
  *
  */
  authorizationToken(): string {
    return localStorage.getItem(storageManager.TOKEN);
  }
}
