import { Injectable } from '@angular/core';
import { storageManager } from '../../core/constant/storage-manager';
import { LocalStorageService } from '../../core/service/local-storage.service';
import { API_END_POINT, API_END_POINT_SOD, API_END_POINT_SOD_DUMMY, API_PRODUCT_DETECTION, SHELF_APIGW, SOD_X_API_KEY } from '../../../environments/environment';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CommonService {
  private http: HttpClient;

  constructor(private localStorage: LocalStorageService, private httpBackend: HttpBackend) {
    this.http = new HttpClient(httpBackend);
  }
  headerTitle = new BehaviorSubject<string>(null);

  /**
 * Set header title to viewed page
 * @param string - title of the page
 */
  setHeaderPageTitle(value: string) {
    this.headerTitle.next(value);
  }

  /**
   * Method handler query params for api
   * @param body data query params
   */
  buildQueryParamsString(body: any) {
    return Object.keys(body)
      .map((key) => {
        const paramValue = body[key].toString() || '';
        return encodeURIComponent(key) + '=' + encodeURIComponent(paramValue);
      })
      .join('&');
  }

  /**
   * Method handler convert params api
   * @param apiUriTemplate api template
   * @param params data want add to params api
   * @return api have data
   */
  buildAPIUrl(apiUriTemplate: string, params?: object) {
    return this.generateUrl(apiUriTemplate, params, API_END_POINT);
  }

  /**
 * Method handler convert params api and build SOD api url
 * @param apiUriTemplate api template
 * @param params data want add to params api
 * @return api have data
 */
  buildAPIUrlSod(apiUriTemplate: string, params?: object) {
    return this.generateUrl(apiUriTemplate, params, API_END_POINT_SOD);
  }

  // TODO: remove later
  buildAPIUrlSodDummy(apiUriTemplate: string, params?: object) {
    return this.generateUrl(apiUriTemplate, params, API_END_POINT_SOD_DUMMY);
  }

  /**
 * Method handler for SOD GET request with API KEY
 * @param url - string
 * @return http get client
 */
  buildSodGetRequest(url: string) {
    const headers = new HttpHeaders({
      'x-api-key': SOD_X_API_KEY
    });
    return this.http.get(url, {
      headers
    });
  }

  /**
 * Method handler for SOD POST request with API KEY
 * @param url - string
 * @param data - any
 * @return http post client
 */
  buildSodPostRequest(url: string, data: any) {
    const headers = new HttpHeaders({
      'x-api-key': SOD_X_API_KEY
    });
    return this.http.post(url, data, {
      headers
    });
  }

  /**
 * Method handler for SOD PUT request with API KEY
 * @param url - string
 * @param data - any
 * @return http put client
 */
  buildSodPutRequest(url: string, data: any) {
    const headers = new HttpHeaders({
      'x-api-key': SOD_X_API_KEY
    });
    return this.http.put(url, data, {
      headers
    });
  }

  /**
 * Method handler convert params api and build SOD api url
 * @param apiUriTemplate api template
 * @param params data want add to params api
 * @return api have data
 */
  buildApiProductDetection(apiUriTemplate: string, params?: object) {
    return this.generateUrl(apiUriTemplate, params, API_PRODUCT_DETECTION);
  }

  /**
 * Method handler convert params api and build Image APIGW
 * @param apiUriTemplate api template
 * @param params data want add to params api
 * @return api have data
 */
  buildApiShelfApigw(apiUriTemplate: string, params?: object) {
    return this.generateUrl(apiUriTemplate, params, SHELF_APIGW);
  }


  generateUrl(apiUriTemplate: string, params?: object, apiEndpoint?: string) {
    let queryParams = null;
    if (params) {
      // tslint:disable-next-line:forin
      for (const key in params) {
        const chkKey = '/{' + key;
        if (apiUriTemplate.indexOf(chkKey) < 0) {
          if (!queryParams) {
            queryParams = {};
          }
          queryParams[key] = params[key];
        }
      }
    }
    // Rebuild query parameter of template
    const urlParts = apiUriTemplate.split('?');
    let newUrl = urlParts[0];
    if (urlParts.length > 1 && queryParams) {
      newUrl = newUrl + '?' + this.buildQueryParamsString(queryParams);
    }

    // Assign value of request parameters
    if (params) {
      Object.keys(params).forEach((key) => (newUrl = newUrl.replace(`{${key}}`, params[key])));
    }
    return `${apiEndpoint}${newUrl}`;
  }

  isAuthenticated() {
    return localStorage.getItem(storageManager.TOKEN);
  }

}
