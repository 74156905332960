import { environment } from '../../../environments/environment';

export const serviceAPI = {
  hostName: environment.host,
  user: {
    login: '/auth/login',
    logout: '/users/logout',
    resetPassword: '/auth/reset-password',
    searchUser: '',
    addUserSupervisor: '/users/supervisor',
    addUser: '/users',
    editUserSupervisor: '/users/supervisor/{userId}',
    editUser: '/users/{userId}',
    getUser: '/users/{userId}',
    getRole: '/roles',
    uuId: '/users/id/random',
    getAllUsers: '/users/list-all?offset={offset}&limit={limit}&search={search}&companyId={companyId}',
    addUserMan: '/users/add-user',
    addSystemAdmin: '/users/system',
    editSystemAdmin: '/users/system/{userId}',
    deleteUser: '/users/{userId}',
  },
  // API for shelf store

  store: {
    listStore: '/bases',
    listEdges: '/bases/{storeId}/edges',
    listEdgesNEC2: '/bases/{storeId}/edges-by-nec2',
    getCamerasByEdgeIdNEC2: '/bases/{storeId}/edges/{edgeId}/cameras-nec2',
    getCamerasByEdgeId: '/bases/{storeId}/edges/{edgeId}/cameras',
    storeDetail: '/bases/{id}',
    updateStore: '/bases/{id}',
    createStore: '/bases',
    getRushHours: '',
    getCameras: '/bases/{id}/cameras',
    getShelves: '/bases/{id}/shelves?search={search}&offset={offset}&limit={limit}&admin={admin}',
    getAllShelves: '/bases/{id}/shelves?admin={admin}',
    deleteStore: '/bases/{id}',
    getUsers: '/bases/{storeId}/users',
    deleteUser: '/bases/{storeId}/users/{userId}',
    setCameras: '/bases/{storeId}/set-cameras-list',
    setCamerasNEC2: '/bases/{storeId}/set-cameras-list-by-nec2',
    deleteCamera: '/cameras/{cameraId}',
    getGvdSnapshotApi: '/bases/{id}/provide-camera-host',
    shelfCategory: '/bases/{baseId}/shelf-categories',
    shelfCategoryId: '/bases/{baseId}/shelf-categories/{categoryId}',
  },
  shelf: {
    getShelves: '/shelves',
    getShelf: '/shelves/{shelfId}',
    getShelfImage: '',
    updateShelfImage: '',
    createShelf: '/shelves',
    updateShelf: '/shelves/{id}',
    deleteShelf: '/shelves/{id}',
    getShelfImages: '/shelves/{id}/images?date={date}&hour={hour}&minute={minute}',
    getShelfImagesByDate: '/shelves/{id}/images?date={date}',
    getCommodityCategory: '/companies/{companyId}/bases/{baseId}'
  },
  camera: {
    getImageByCamera: '/cameras/{cameraId}/view?date={date}&hour={hour}&minute={minute}',
  },
  icon: {
    getIconByStoreId: '/shelves/icons/list',
  },
  image: {
    getShelfConfig: '/shelves/{shelfId}/get-image-config?cameraId={cameraId}',
    cropImage: '/crop/process',
    getImageByNEC2: '/rack/facilities?user_id={user_id}&cameraId={cameraId}&from={from}&to={to}&bucket={bucket}&env={env}',
    // getImageByBaseIdNEC2: '/rack/facilities?user_id={user_id}&cameraId={cameraId}&base_id={base_id}&bucket={bucket}&env={env}',
    getBinaryFile: '/image/get-base64-image?key={key}'
  },
  company: {
    searchCompanies: '/companies?search={search}&offset={offset}&limit={limit}',
    getListStore: '/companies/{id}/bases?search={search}&offset={offset}&limit={limit}',
    deleteUser: '/companies/{id}/supervisors/{userid}',
    getFile: '/companies/{id}/supervisors/download',
    uploadFile: '/companies/{id}/supervisors/upload',
    uuId: '/companies/id/random',
    addUser: '/companies/{companyId}/manager-company',
    deleteUserSystem: '/companies/{companyId}/manager-company/{userId}',
    filterUser: '/companies/{companyId}/filter-user?s={s}',
    getUserSystem: '/companies/{companyId}/system?search={search}&offset={offset}&limit={limit}',
  },
  nec: {
    getCamerasByUserId: '/neccloud/cameras?user_id={user_id}',
    getCamerasNEC2ByUserId: '/neccloud/cameras2??user_id={user_id}',
  },
  gvd: {
    detectFaces: '/gvdcloud/faces-detection',
    getVirtual: '/gvdcloud/get-virtual?path={path}',
    logon: '/gvdcloud/logon',
    snapshot: '/gvdcloud/snapshot',
    saveImage: '/save-image-to-s3-dev'
  },
  problems: {
    getListProblems: '/problems?offset={offset}&limit={limit}',
    confirmedProblems: '/problems/{id}/confirm',
    getNotificationsCount: '/problems/notifications/count',
    export: '/problems/download',
  },
  area: {
    settingCommon: '/sod/v2/areas/{edgeId}/{cameraId}/{shelfName}',
    settingGeneric: '/sod/v2/areas/{edgeId}/{cameraId}/{shelfName}/all',
    settingByAreaNumber: '/sod/v2/areas/{edgeId}/{cameraId}/{shelfName}/{areaNumber}',
    settingByAreaNumberWithRevision: 'sod/v2/areas/{edgeId}/{edgeId}/{edgeId}/{edgeId}?revision={revision}',
    getProcessedImage: '/sod/v2/areas/{edgeId}/{cameraId}/{shelfName}/all/histories?type={type}&area={area}',
  },
  picking: {
    horRecog: '/hor_recog',
    pickingList: '/sod/v2/areas/{edgeId}/{cameraId}/{shelfName}/all/pickinglist',
    allocation: '/sod/v2/areas/{edgeId}/{cameraId}/{shelfName}/all/allocation'
  },
  replenishment: {
    rate: '/sod/v2/areas/{edgeId}/{cameraId}/{shelfCode}/all/histories?image={image}',
    rateWithDateTime: '/sod/v2/areas/{edgeId}/{cameraId}/{shelfCode}/all/histories?image={image}&datetime={datetime}'
  },
  material : {
    materialTraining: '/sod/v2/materials/training',
    materialModel: '/sod/v2/materials/models'
  },
  janCode : {
    getJanCode: '/jancode?baseId={baseId}',
    addJanCode: '/jancode'
  }
};
