// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  host: 'http://10.16.88.70:80/',
};
 // *********** API GATEWAYS **********/
export const API_END_POINT = 'https://bwsceyfpg3.execute-api.ap-northeast-1.amazonaws.com/Prod';
export const SHELF_APIGW = 'https://bwsceyfpg3.execute-api.ap-northeast-1.amazonaws.com/Prod'; // change from IMAGE_APIGW to SHELF_APIGW
export const API_END_POINT_SOD = 'https://sod.shelfmonitoring.com';
export const API_PRODUCT_DETECTION = 'https://ec2-35-75-158-252.ap-northeast-1.compute.amazonaws.com:31000/api';
export const URL_CLOUD_FRONT = 'https://di6zbucsx0e2p.cloudfront.net/';
export const API_END_POINT_SOD_DUMMY = 'https://s0oiicohc8.execute-api.ap-northeast-1.amazonaws.com/prod'; // USE Dummy SOD API
 // *********** API KEYS **********/
export const SOD_X_API_KEY = 'CTgOchWEBZ1qK3FJTiSiu8ey5FVqwJuf72XX7Zr1'; // PROD
// export const SOD_X_API_KEY = 'dxHtNQHRMA8mmVeHtEsPs5HI8dtmX8dk5GcRXKd8'; // DEMO
// export const SOD_X_API_KEY = 'pGbDXvy2P15UXtxZjlPg27zWmUFavxSVlaHqC4hh'; // DEV

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
