import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
import { pageUrl } from '../constant/page-url';
import { UserStoreSevice } from '../store/user-store.service';
import { NavigationExtras } from '@angular/router';

@Injectable()
export class ServiceInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private notificationService: NotificationService,
    private userStoreSevice: UserStoreSevice,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.auth.authorizationToken();

    // Build http request header
    let headers = req.headers;

    if (authToken) {
      headers = headers.append('Authorization', 'Bearer ' + authToken);
    } else {
      headers = headers.append('Content-Type', 'application/json');
    }

    const authReq = req.clone({ headers });
    return next.handle(authReq).pipe(
      catchError((err) => {
        if (err.status === 401 || err.status === 403) {
          localStorage.clear();
          const extras: NavigationExtras = {
            queryParams: {
              t: err.error.msg ? err.error.msg.content : 'Something bad happened; please try again later.',
            },
          };
          this.router.navigate([pageUrl.login], extras);
        } else {
          if (err.error.msg && err.error.msg.content) {
            this.notificationService.onNotificationError(err.error.msg.content);
          } else {
            this.notificationService.onNotificationError('Something bad happened; please try again later.');
          }
        }
        if (err.status === 602) {
          localStorage.clear();
          const extras: NavigationExtras = {
            queryParams: {
              t: err.error.msg.content,
            },
          };
          this.router.navigate([pageUrl.access_denined], extras);
        }

        return throwError('Something bad happened; please try again later.');
      })
    );
  }
}
