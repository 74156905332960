import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, from, of } from 'rxjs';

import { serviceAPI } from '../constant/service-api';
import { CommonService } from './common.service';
import { postLoginUser, listAllUser } from './data-fake/user.data';
import { delay, map } from 'rxjs/operators';
import { PageMeta } from '../model/pagging.model';
import { DefaultPageMeta } from '../constant/paginator-config';
import { PostUserMan } from '../model/user.models';
import { UserRoles } from 'src/app/user/user.data';

@Injectable()
export class UserService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  /*
   * Construct a post request login
   * @return an 'Observable'
   *
   */
  postLoginUser(body?): Observable<any> {
    const api = this.commonService.buildAPIUrl(serviceAPI.user.login);
    return this.http.post(api, body);
  }

  /**
   * Medthod part url
   * @param api  of user
   * @param body data send sever
   */

  partUrl(api, body) {
    if (body) {
      const urlParam = Object.keys(body)
        .map((key) => {
          const paramValue = body[key] || '';
          return encodeURIComponent(key) + '=' + encodeURIComponent(paramValue);
        })
        .join('&');
      return (api = api + '?' + urlParam);
    }
  }

  /**
   * Function handler call api logout
   */
  userLogout(): Observable<any> {
    const api = this.commonService.buildAPIUrl(serviceAPI.user.logout);
    return this.http.post(api, {});
  }

  /**
   * reset password by recovery code
   * @param body contain recovery code as well as new password
   */
  resetPassword(body: { recovery_code: string; new_password: string; confirmed_password: string }): Observable<any> {
    const api = this.commonService.buildAPIUrl(serviceAPI.user.resetPassword);
    return this.http.post(api, body);
  }

  searchUser(keyword: string): Observable<any> {
    // const api = this.commonService.buildAPIUrl(serviceAPI.user.searchUser, {keyword});
    // this.http.get(api);
    const users = listAllUser.result.users.filter((user) => user.username.includes(keyword));
    return of(users).pipe(delay(500));
  }

  /**
   * add User
   * @param body send server
   * @param type send server
   */
  addUser(body, type: string): Observable<any> {
    let param;
    switch (type) {
      case UserRoles.supervisor:
        param = serviceAPI.user.addUserSupervisor;
        break;
      case UserRoles.systemAdmin:
        param = serviceAPI.user.addSystemAdmin;
        break;
      case UserRoles.normalUser:
        param = serviceAPI.user.addUser;
        break;
    }

    const api = this.commonService.buildAPIUrl(param);
    return this.http.post(api, body);
  }

  /**
   * edit User
   * @param body send server
   * @param type send server
   * @params type of user
   *
   */
  editUser(body, params: { userId: string }, type: string): Observable<any> {
    let param;
    switch (type) {
      case UserRoles.normalUser:
        param = serviceAPI.user.editUser;
        break;
      case UserRoles.supervisor:
        param = serviceAPI.user.editUserSupervisor;
        break;
      case UserRoles.systemAdmin:
        param = serviceAPI.user.editSystemAdmin;
        break;
      case UserRoles.othersUser:
        param = serviceAPI.user.editUser;
        break;
    }
    const api = this.commonService.buildAPIUrl(param, params);
    return this.http.put(api, body);
  }

  /**
   * get detail User
   * @param param link url
   *
   */
  getDetailUser(param): Observable<any> {
    const api = this.commonService.buildAPIUrl(serviceAPI.user.getUser, param);
    return this.http.get(api);
  }
  /**
   * get role User
   *
   */
  getRoleUser(): Observable<any> {
    const api = this.commonService.buildAPIUrl(serviceAPI.user.getRole);
    return this.http.get(api).pipe(
      map((response: any) => {
          const { result } = response;
          result.forEach(element => {
            switch (element.name) {
              case 'Others':
                element.description = 'Use to login customer web and mobile with role Others';
                break;
            }
          });
        return response;
      })
    );
  }

  uuId(): Observable<any> {
    const api = this.commonService.buildAPIUrl(serviceAPI.user.uuId);
    return this.http.get(api);
  }

  /**
   * get all user in a company, opt with filter
   * @param companyId search all user which belong to that company
   * @param pageMeta paging config
   */
  getAllUsers(companyId: string, pageMeta: PageMeta = DefaultPageMeta): Observable<any> {
    const api = this.commonService.buildAPIUrl(serviceAPI.user.getAllUsers, Object.assign(pageMeta, { companyId }));
    return this.http.get(api);
  }

  deleteUser(userId: string): Observable<any> {
    const api = this.commonService.buildAPIUrl(serviceAPI.user.deleteUser, { userId });
    return this.http.delete(api);
  }

  addUserMan(body: PostUserMan): Observable<any> {
    const api = this.commonService.buildAPIUrl(serviceAPI.user.addUserMan);
    return this.http.post(api, body);
  }
}
