import { PageMeta } from '../model/pagging.model';

export const PaginatorConfig = {
  pageSizeOptions: [5, 10, 25, 100],
  pageSize: 10,
  pageIndex: 1
};

export const DefaultPageMeta: PageMeta = {
  offset: 0,
  limit: 10,
  search: '',
};
