import { Injectable } from '@angular/core';

import { HttpClient, HttpRequest } from '@angular/common/http';

@Injectable()
export class UploadFileService {
  FILE_SIZE_MAX = 100;
  typetDefaul = ['application/vnd.ms-excel', 'text/csv'];
  constructor(private http: HttpClient) {
  }


  /*
  * Construct a requests file to serve
  *
  * @return an 'Observable'
  */
  requests(body) {
    return this.http.request(body);
  }


  /*
  * Construct a check file upload
  * @param {files} file of user upload
  * @param type user upload
  *
  */
  checkFileUpload(file) {
    if (!file) {
      return;
    }
    let code;
    if (this.typetDefaul.indexOf(file.type) <= -1) {
      code = 201;
    } else
      if (file.size / 1024 / 1024 > this.FILE_SIZE_MAX) {
      code = 202;
    } else if (file.size / 1024 / 1024 <= 0) {
      code = 203;
    }
    return code;
  }

  /*
  * Construct a represents an outgoing request, including URL
  *
  * @return an 'Observable'
  */
  requestPostFile(url, file) {
    const formData = new FormData();
    formData.append('files', file, file.name);

    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
    });
    return req;
  }

  /*
  * Method handle upload progress
  * @param {eventRes} event of request upload
  *
  */
  handleUploadProgress(eventRes) {
    // This is an upload progress event. Compute and show the % done:
    const percentDone = Math.round(100 * eventRes.loaded / eventRes.total);
    return percentDone;
  }

}
