import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserService } from './service/user.service';
import { JanCodeService } from './service/jancode.service';
import { AuthService } from './service/auth.service';
import { NotificationService } from './service/notification.service';
import { CanActivatePageService } from '../core/service/can-activate-page.service';
import { UserStoreSevice } from './store/user-store.service';
import { LocalStorageService } from './service/local-storage.service';
import { CommonService } from './service/common.service';
import { ProjectStoreService } from './store/project-store.service';
import { CommentStoreService } from './store/comment-store.service';
import { ServiceInterceptor } from './service/service-interceptor';
import { UploadFileService } from './service/upload-file.service';
import { ProblemReportStoreService } from './store/problem-report-store.service';

@NgModule({
  providers: [
    UserService,
    AuthService,
    CanActivatePageService,
    NotificationService,
    UserStoreSevice,
    LocalStorageService,
    CommonService,
    ProjectStoreService,
    CommentStoreService,
    UploadFileService,
    ProblemReportStoreService,
    JanCodeService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServiceInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {}
