// link to page-key.ts
export const titlePage = {
  login: 'Login',
  store: 'Store',
  storeDetail: 'Store Detail',
  addStore: 'Add New Store',
  shelf: 'List Shelves',
  shelfDetail: 'Shelf Detail',
  addShelf: 'Add New Shelf',
  imageSelector: 'Image Selector',
  addCompany: 'Add New Company',
  companyDetail: 'Company Detail',
  listCompany: 'Companies',
  resetPassword: 'Reset Your Password',
  addUser: 'Add User',
  editUser: 'Edit User',
  problemReport: 'Problem Report',
  listUserMan: 'User Management',
  janCodeManagementList: 'JAN Code Management',
  addUserMan: 'User Management | Add User',
  editUserMan: 'User Management | Edit User',
};
