import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { trigger, style, animate, transition, query, animateChild, useAnimation } from '@angular/animations';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { NotificationService } from './core/service/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { fadeAnimation } from './core/animations/animations';
import { LoadingIndicatorService } from './core/service/loading-indicator.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('herePower', [
      transition('void => *', [
        style({
          transform: 'translate(100px, 0)',
          opacity: 0,
        }),
        animate('250ms ease-out'),
      ]),
      transition('* => void', [
        animate(
          '150ms ease-out',
          style({
            transform: 'translate(100px, 0)',
            opacity: 0,
          })
        ),
      ]),
    ]),
    trigger('fade', [
      transition('* => home, * => login, * => default', [
        useAnimation(fadeAnimation, { params: { time: '0.5s' } }),
        query(':enter', animateChild(), { optional: true }),
      ]),
    ]),
  ],
  providers: [TranslateService],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('headerElem') headerElem: ElementRef;
  isProgress = false;
  notifiApp: boolean;
  notifiMessage: string;
  notifiType: string;
  tokenError: string;

  isLoading = false;

  private subscriptions: Array<Subscription> = [];

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private loadingIndicatorService: LoadingIndicatorService
  ) {
    this.router.events.subscribe((e) => {
      this.handlerSubcribeRouter(e);
    });
    translate.addLangs(['en', 'vi']);
    translate.setDefaultLang('en');
  }

  ngOnInit() {
    // call method errors
    this.handleNotification();
    this.handleLoadingIndicator();
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  handleLoadingIndicator() {
    this.subscriptions.push(
      this.loadingIndicatorService.emitter.subscribe(($event) => {
        this.isLoading = $event.isLoading;
      })
    );
  }

  /*
   * Method subcribe router
   *
   */
  handlerSubcribeRouter(e) {
    if (e instanceof NavigationStart) {
      this.notifiApp = false;
      this.isProgress = true;
    } else if (e instanceof NavigationEnd) {
      this.isProgress = false;
      this.notifiApp = false;
    }
  }

  /*
   * Method show notification
   *
   */
  handleNotification() {
    this.notificationService.stNotification.subscribe((value) => {
      this.notifiMessage = value.msg;
      this.notifiType = value.type;
      this.notifiApp = true;
      this.isProgress = false;
      this.handleAutoCloseNotification();
    });
  }

  /*
   * Method handler reload app
   *
   */
  handleOnReload() {
    location.reload();
    this.handleOnNoficationCancel();
  }

  /*
   * Method when user cancel notication
   *
   */
  handleOnNoficationCancel() {
    this.notifiApp = false;
  }

  /*
   * Method auto hide notication
   *
   */
  handleAutoCloseNotification() {
    const close = setTimeout(() => {
      this.handleOnNoficationCancel();
      clearTimeout(close);
    }, 2000);
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
}
