import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class NotificationService {
  public stNotification: EventEmitter<any> = new EventEmitter<any>();

  onNotificationSuccess(mgs, callback?: Function, delay = 1000) {
    if (!mgs || typeof mgs === 'object') {
      mgs = 'Success';
    }
    const data = {
      type: 'success',
      msg: mgs,
    };
    this.stNotification.emit(data);
    if (callback) {
      setTimeout(() => {
        callback();
      }, delay);
    }
  }

  onNotificationError(mgs, callback?: Function, delay = 1000) {
    if (!mgs || typeof mgs === 'object') {
      mgs = 'Server error please try again';
    }
    const data = {
      type: 'error',
      msg: mgs,
    };
    this.stNotification.emit(data);
    if (callback) {
      setTimeout(() => {
        callback();
      }, delay);
    }
  }
}
