import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class UserStoreSevice {

  public userLogin: EventEmitter<any> = new EventEmitter<any>();
  public userLogout: EventEmitter<any> = new EventEmitter<any>();
  public userUploadAvatar: EventEmitter<any> = new EventEmitter<any>();
  public groupUserEvent: EventEmitter<any> = new EventEmitter<any>();
  public userInLogin: EventEmitter<any> = new EventEmitter<any>();
  /*
  * Method listening when login sucssess
  *
  */
  listeningLoginSucssess() {
    this.userLogin.emit();
  }

  /**
   * Method listening when user logout
   *
   */
  listeningLogout() {
    this.userLogout.emit();
  }

  /**
   * Method listening user upload avatar
   */
  listteningUploadAvatar() {
    this.userUploadAvatar.emit();
  }

  /**
   * Broadcast active group user is changed
   */
  activeGroupUser(group) {
    this.groupUserEvent.emit(group);
  }
  /**
   * Method listening user to login
   */
  listeningUserInLogin() {
    this.userInLogin.emit();
  }
}
