export const pageUrl = {
  login: '/login',
  access_denined: '/access-denined',
  store: '/store',
  user: '/user/add',
  userEdit: '/user/edit',
  companies: '/companies',
  area: '/area',
  notificationSetting: '/notification-setting',
  shelfReplenishment: '/shelf-replenishment',
  userManagement: {
    list: '/user-management',
    add: '/user-management/add',
    edit: '/user-management/edit',
  },
  janCodeManagement: { list: '/jan-code-management' }
};
