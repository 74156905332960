import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from, of } from "rxjs";
import { serviceAPI } from "../constant/service-api";
import { CommonService } from "./common.service";
import { PostJanCode } from "../model/jan-code.model";

@Injectable()
export class JanCodeService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  /**
   * get jan code list
   * @param baseId base id of the store
   */
  getJanCodeList(param: { baseId: string }): Observable<any> {
    const api = this.commonService.buildAPIUrl(
      serviceAPI.janCode.getJanCode,
      param
    );
    return this.http.get(api);
  }

  /**
   * add new jan code
   * @param body a jan code object
   */
  addJanCode(body: PostJanCode): Observable<any> {
    const api = this.commonService.buildAPIUrl(serviceAPI.janCode.addJanCode);
    return this.http.post(api, body);
  }
}
