import { animate, state, style, trigger, transition, animation, query, animateChild, stagger, useAnimation } from '@angular/animations';

export const loadFadeTab = trigger('load', [
  transition('loading => loaded', [
    style({ opacity: 0 }),
    animate('0.9s cubic-bezier(.56,.02,.23,.68)', style({ opacity: 1 })),
    query(':enter', animateChild(), { optional: true }),
  ]),
  transition('loaded => loading', [style({ opacity: 0 }), animate('0.9s cubic-bezier(.56,.02,.23,.68)')]),
]);
export const fadeAnimation = animation([style({ opacity: 0 }), animate('{{ time }} cubic-bezier(.56,.02,.23,.68)', style({ opacity: 1 }))]);
export const fadeOut = animation([style({ opacity: 1 }), animate('{{ time }} cubic-bezier(.56,.02,.23,.68)', style({ opacity: 0 }))]);

export const expandAnimation = trigger('expand', [
  transition(':enter', [
    query(':enter', [
      style({ opacity: 0, height: '0px', transform: 'translateY(-100px)' }),
      stagger(50, [animate('300ms ease-out', style({ opacity: 1, height: '*', transform: 'translateY(0)' }))]),
    ]),
  ]),
]);

export const fadeAnimate = trigger('fadeAnimate', [
  transition(':enter', [useAnimation(fadeAnimation, { params: { time: '0.3s' } }), query(':enter', animateChild(), { optional: true })]),
  transition(':leave', [useAnimation(fadeOut, { params: { time: '0.3s' } })]),
]);

export const ANIMATIONS = {
  loadFadeTab,
  fadeAnimation,
  expandAnimation,
  fadeAnimate,
};
