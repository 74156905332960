import { Component, OnInit, HostListener, OnDestroy, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { storageManager } from 'src/app/core/constant/storage-manager';
import { CropperService } from 'src/app/core/store/cropper-store.service';
import { UserService } from 'src/app/core/service/user.service';
import { Router } from '@angular/router';
import { pageUrl } from 'src/app/core/constant/page-url';
import { LayoutStoreService } from 'src/app/core/store/layout-store.service';
import { ProblemReportService } from 'src/app/core/service/problem-report.service';
import { ProblemReportStoreService } from 'src/app/core/store/problem-report-store.service';
import { Roles } from '../layout.data';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/service/common.service';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
})
export class DashboardLayoutComponent implements OnInit, OnDestroy, AfterContentChecked  {
  iscollapsed = false;
  email: string;
  count: any;
  isAdmin = false;
  companyId: string;
  title = '';

  // subscription
  unconfirmedReportSub: Subscription;
  constructor(
    private layoutStoreService: LayoutStoreService,
    private problemReportService: ProblemReportService,
    private problemReportStoreService: ProblemReportStoreService,
    private userService: UserService,
    private router: Router,
    private commonService: CommonService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  @HostListener('window:resize', ['$event']) onresize(e) {
    if (document.documentElement.clientWidth < 960) {
      this.iscollapsed = true;
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnInit() {
    // get User Info Local
    this.getUserInfoLocal();

    // init Count Problem Report
    this.initCountProblemReport();

    this.problemReportStoreService.stReport.subscribe(() => {
      // init Count Problem Report
      this.initCountProblemReport();
    });

    this.unconfirmedReportSub = this.problemReportStoreService.unconfirmedReport.subscribe((res) => {
      this.count = res || 0;
    });

    this.commonService.headerTitle.subscribe((res) => {
      if (res != null) {
        this.title = res;
      }
    });
  }

  ngOnDestroy() {
    if (this.unconfirmedReportSub) {
      this.unconfirmedReportSub.unsubscribe();
    }
  }

  getUserInfoLocal() {
    this.email = JSON.parse(localStorage.getItem(storageManager.USER_INFO)).email;
    this.isAdmin = localStorage.getItem(storageManager.ROLE) === Roles.admin;
    this.companyId = JSON.parse(localStorage.getItem(storageManager.USER_INFO)).companyId;
  }

  toggleCollapse() {
    this.iscollapsed = !this.iscollapsed;
    // timeout equal to the animation time (css transition);
    setTimeout(() => {
      this.layoutStoreService.collapseSideBar(this.iscollapsed);
    }, 350);
  }

  logout() {
    this.userService.userLogout().subscribe(
      () => {
        localStorage.clear();
        this.router.navigate([pageUrl.login]);
      },
      (err) => {}
    );
  }

  /**
   * Method: init list problem report
   *
   */
  initCountProblemReport() {
    this.problemReportService.getNotificationsCount().subscribe((res) => {
      if (res) {
        this.count = res.result;
        this.problemReportStoreService.unconfirmedReport.emit(this.count || 0);
      }
    });
  }
}
