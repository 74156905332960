import { Injectable, EventEmitter } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LayoutStoreService {
  public listenCollapseSideBar = new EventEmitter<boolean>();
  constructor() {}

  collapseSideBar(isCollapsed: boolean) {
    this.listenCollapseSideBar.emit(isCollapsed);
  }
}
