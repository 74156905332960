import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AtTranslateModule } from '../at-translate/at-translate.module';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { DashboardLayoutComponent } from './dashboard-layout/dashboard-layout.component';

@NgModule({
  declarations: [DefaultLayoutComponent, DashboardLayoutComponent],
  imports: [
    RouterModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    CommonModule,
    AtTranslateModule,
    MatIconModule,
  ],
  exports: [DefaultLayoutComponent, DashboardLayoutComponent],
})
export class LayoutModule {}
