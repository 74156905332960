import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class CommentStoreService {

  public changeStatusCommentReview: EventEmitter<any> = new EventEmitter<any>();

  /*
  * Method listening when user change status comment review
  * @param data is status of comment
  *
  */
  listeningActionReview (data) {
    this.changeStatusCommentReview.emit(data);
  }

}
