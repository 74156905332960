import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class ProblemReportStoreService {
  public stReport: EventEmitter<any> = new EventEmitter<any>();
  public unconfirmedReport: EventEmitter<number> = new EventEmitter<number>();

  /**
   * Method listening admin report
   *
   */
  listeningProblem() {
    this.stReport.emit();
  }
}
