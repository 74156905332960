import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultLayoutComponent } from './layout/default-layout/default-layout.component';
import { DashboardLayoutComponent } from './layout/dashboard-layout/dashboard-layout.component';
import { storageManager } from 'src/app/core/constant/storage-manager';
import { ADMIN, STORE_ADMIN } from './core/constant/roles';
const isCustomer = localStorage.getItem('role') && ((localStorage.getItem('role') !== ADMIN));
const userInfo = JSON.parse(localStorage.getItem(storageManager.USER_INFO));
const companyId = userInfo ? userInfo.companyId : '';

/**
 * Note: Please note the following role configuration to access specific pages
 * Case route.data.role === [] : allow any user role to access the page
 * Case route.data.role === undefined : if role is not defined allow any user role to access the page
 * Case route.data.role === [X, X2]: allow access to user X and X2
 */

const routes: Routes = [
  {
    path: '',
    redirectTo: isCustomer ? `shelf-replenishment/${companyId}` : 'companies',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: DefaultLayoutComponent,
    loadChildren: './login/login.module#LoginModule',
    data: { animation: 'login' },
  },
  {
    path: 'reset-password',
    component: DefaultLayoutComponent,
    loadChildren: './reset-password/reset-password.module#ResetPasswordModule',
    data: { animation: 'default', role: [ADMIN, STORE_ADMIN] },
  },
  {
    path: 'companies',
    component: DashboardLayoutComponent,
    loadChildren: './company/company.module#CompanyModule',
    data: { animation: 'default', role: [ADMIN] },
  },
  {
    path: 'store',
    component: DashboardLayoutComponent,
    loadChildren: './store/store.module#StoreModule',
    data: { animation: 'default', role: [ADMIN, STORE_ADMIN] },
  },
  {
    path: 'shelf',
    component: DashboardLayoutComponent,
    loadChildren: './shelf/shelf.module#ShelfModule',
    data: { animation: 'default', role: [ADMIN] },
  },
  {
    path: 'area',
    component: DashboardLayoutComponent,
    loadChildren: './area/area.module#AreaModule',
    data: { animation: 'default', role: [ADMIN, STORE_ADMIN] },
  },
  {
    path: 'notification-setting',
    component: DashboardLayoutComponent,
    loadChildren: './notification-setting/notification-setting.module#NotificationSettingModule',
    data: { animation: 'default', role: [ADMIN, STORE_ADMIN] },
  },
  {
    path: 'picking',
    component: DashboardLayoutComponent,
    loadChildren: './picking/picking.module#PickingModule',
    data: { animation: 'default', role: [ADMIN] },
  },
  {
    path: 'allocation-registration',
    component: DashboardLayoutComponent,
    loadChildren: './allocation-registration/allocation-registration.module#AllocationRegistrationModule',
    data: { animation: 'default', role: [ADMIN] },
  },
  {
    path: 'shelf-replenishment',
    component: DashboardLayoutComponent,
    loadChildren: './replenishment/replenishment.module#ReplenishmentModule',
    data: { animation: 'default', role: [STORE_ADMIN] },
  },
  {
    path: 'replenishment-support',
    component: DashboardLayoutComponent,
    loadChildren: './replenishment-support/replenishment-support.module#ReplenishmentSupportModule',
    data: { animation: 'default', role: [STORE_ADMIN] },
  },
  {
    path: 'user',
    component: DashboardLayoutComponent,
    loadChildren: './user/user.module#UserModule',
    data: { animation: 'default', role: [ADMIN] },
  },
  {
    path: 'problem-report',
    component: DashboardLayoutComponent,
    loadChildren: './problem-report/problem-report.module#ProblemReportModule',
    data: { animation: 'default', role: [ADMIN] },
  },
  {
    path: 'user-management',
    component: DashboardLayoutComponent,
    loadChildren: './user-management/user-management.module#UserManagementModule',
    data: { animation: 'default', role: [ADMIN] },
  },
  {
    path: 'jan-code-management',
    component: DashboardLayoutComponent,
    loadChildren: './jan-code-management/jan-code-management.module#JanCodeManagementModule',
    data: { animation: 'default', role: [ADMIN] },
  },
  {
    path: '**',
    component: DefaultLayoutComponent,
    loadChildren: './not-found/not-found.module#NotFoundModule',
    data: { animation: 'default' },
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
