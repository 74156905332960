export const postLoginUser = {
  code: 200,
  message: 'ok',
  data: {
    token: 'abc',
    username: 'haolq1',
    userId: 1,
  },
};
export const listAllUser = {
  msg: {
    code: 200,
    content: 'success',
  },
  result: {
    users: [
      {
        id: '001',
        username: 'haolq1',
        avatar: 'https://www.nasa.gov/sites/default/files/styles/image_card_4x3_ratio/public/thumbnails/image/tcl-4_reno-50-iotd.jpg',
      },
      {
        id: '002',
        username: 'tungbt11',
        avatar: 'https://media.sproutsocial.com/uploads/2017/02/10x-featured-social-media-image-size.png',
      },
      {
        id: '003',
        username: 'thiennd5',
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2jot4XsZ14GZfN3iiSsSBK3tig9PnSQbx0yp2MAyWnuuTAXxE',
      },
      { id: '004', username: 'nghianc', icon: 'far fa-address-book color' },
      { id: '005', username: 'tunn4', avatar: undefined, icon: 'fab fa-airbnb' },
      {
        id: '006',
        username: 'duytd6',
        avatar: 'https://www.nasa.gov/sites/default/files/styles/image_card_4x3_ratio/public/thumbnails/image/tcl-4_reno-50-iotd.jpg',
      },
    ],
  },
};
