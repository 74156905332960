/**
 * Copyright (C) 2019 Global Smart Technologies - All Rights Reserved
 *
 */

 import {
  Injectable
} from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  NavigationEnd
} from '@angular/router';
import {
  AuthService
} from './auth.service';
import {
  pageUrl
} from '../constant/page-url';
import {
  Title
} from '@angular/platform-browser';
import {
  titlePage
} from '../../core/constant/title-page';
import jwt_decode from 'jwt-decode';
import moment from 'moment';

@Injectable()
export class CanActivatePageService implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private titleService: Title
  ) {}

  /**
   * Method: Checks if user can activate the component
   * @param route activated page snapshot
   * @returns true if component can be activated by the current user
   */
  canActivate(route: ActivatedRouteSnapshot): boolean {
    // update page title

    this.updatePageTitle(route.data.activatedPageKey);
    if (route.data.activatedPageKey === 'login') {
      return true;
    }
    // proceed if route has no role assigned
    if (!route.data.role || !route.data.role.length) {
      return true;
    }
    // Verify user is authorized
    const token = this.auth.authorizationToken();
    if (token) {
      try {
        const decodedToken: any = jwt_decode(token);
        if (decodedToken.exp <= moment().unix()) {
          return this.logOut();
        }
      } catch (e) {
        console.error(e);
        return this.logOut();
      }
    } else {
      return this.logOut();
    }

    return this.checkUserRouteAccess(route);
  }

  logOut() {
    localStorage.clear();
    this.router.navigate([pageUrl.login]);
    return false;
  }

  /**
   * Method: handle user role access for every page
   * @param route activated page snapshot
   */
  checkUserRouteAccess(route: ActivatedRouteSnapshot) {
    // Verify user is authorized
    const userRole = localStorage.getItem('role');
    if (!route.data.role.includes(userRole)) {
      this.router.navigate([pageUrl.access_denined]);
      return false;
    }
    return true;
  }

  /**
   * Method: handle update page title
   * @param activatedPageKey activated page key
   */
  updatePageTitle(activatedPageKey: string) {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.titleService.setTitle(titlePage[activatedPageKey]);
      }
    });
  }
}
